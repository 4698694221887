const lang = document.documentElement.lang.slice(0, 2);

const createTable = (labels, objects, container) => {
  const tableResponsive = document.createElement('div');
  tableResponsive.classList.add('table-responsive');

  let objectLabels = [];
  for (let i = 0; i < objects.length; i++) {
    for (let key in objects[i]) {
      if (objectLabels.indexOf(key) === -1) {
        objectLabels.push(key);
      }
    }
  }

  let table = document.createElement('table');
  table.classList.add('table', 'table-hover', 'table-striped', 'table-bordered');
  let thead = document.createElement('thead');
  let tbody = document.createElement('tbody');

  let theadTr = document.createElement('tr');
  for (let i = 0; i < labels.length; i++) {
    let theadTh = document.createElement('th');
    theadTh.innerHTML = labels[i];
    theadTr.appendChild(theadTh);
  }
  thead.appendChild(theadTr);
  table.appendChild(thead);

  function numberWithCommas(lang, x) {
    let parts = x.toString().split(".");
    if (lang === 'pl') {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parts.join(".");
  }

  for (let j = 0; j < objects.length; j++) {
    let tbodyTr = document.createElement('tr');
    for (let k = 0; k < objectLabels.length; k++) {
      let tbodyTd = document.createElement('td');
      if (k === 1) {
        if (typeof objects[j][objectLabels[k]] === 'number') {
          if (lang === 'pl') {
            tbodyTd.innerHTML = numberWithCommas(lang, parseFloat(objects[j][objectLabels[k]]).toFixed(2).toLocaleString('en-GB').replace('.', ','));
          } else {
            tbodyTd.innerHTML = numberWithCommas(lang, parseFloat(objects[j][objectLabels[k]]).toFixed(2).toLocaleString('en-GB').replace('.', ',').replace(',', '.'));
          }
        }
      } else if (k === 2) {
        if (typeof objects[j][objectLabels[k]] === 'number') {
          if (lang === 'pl') {
            tbodyTd.innerHTML = parseFloat(objects[j][objectLabels[k]]).toFixed(2).toLocaleString('en-GB').replace('.', ',');
          } else {
            tbodyTd.innerHTML = parseFloat(objects[j][objectLabels[k]]).toFixed(2).toLocaleString('en-GB').replace('.', ',').replace(',', '.');
          }
        }
      } else {
        tbodyTd.innerHTML = objects[j][objectLabels[k]];
      }

      tbodyTr.appendChild(tbodyTd);
    }
    tbody.appendChild(tbodyTr);
  }
  table.appendChild(tbody);

  container.appendChild(table);
};

const appendPolonia = (container) => {
  // eslint-disable-next-line no-undef
  const xmlUrl = ajax_object.data_url;
  // eslint-disable-next-line no-undef
  const xmls = ajax_object.data_body;

  const poloniaData = JSON.parse(xmls[xmlUrl]);

  const lang = document.documentElement.lang.slice(0, 2);

  const labelsPl = ['Data wyliczenia', 'Wartość obrotów [w mln PLN]', 'Stawka [%]'];
  const labelsEng = ['Calculation date', 'Turnover [bln PLN]', 'Rate [%]'];

  if (lang === 'pl') {
    createTable(labelsPl, poloniaData, container);
  } else {
    createTable(labelsEng, poloniaData, container);
  }
};

export { appendPolonia };
