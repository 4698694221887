// Load the exporting module.
import { setArrLang } from '../setTranslation';
import { toggleSpinner } from '../../spinners/toggleSpinner';
import Highcharts from 'highcharts/highstock';

const initExchangeRatesMonthChart = () => {
  const chartContainer = document.getElementById('chart-month'),
    chartLang = document.documentElement.lang.slice(0, 2),
    spinner = document.querySelector('.spinner-rates');
  let stops;

  if (chartContainer) {
    if (localStorage.getItem('switch-state') && localStorage.getItem('switch-state') === 'true') {
      stops = [
        [0, 'rgba(255, 255, 255, 0.45)'],
        [1, 'rgba(255,255,255,0)'],
      ];
    } else {
      stops = [
        [0, 'rgba(125, 175, 225, 0.45)'],
        [1, 'rgba(255,255,255,0)'],
      ];
    }

    const getResponse = () => {
      return  $.ajax({
        type: 'GET',
        // eslint-disable-next-line no-undef
        url: ajax_object.ajax_url,
        dataType: 'json',
        data: { action: 'get_exchange_rates_a' },
        beforeSend: toggleSpinner(spinner),
        success: function (response) {
          toggleSpinner(spinner);
          return response;
        },
      });
    };

// Get Data and format to Highcharts pattern data
    getResponse().then((data) => {
      const getData = (response) => {
        // Get response from Wordpress Ajax
        let ratesArr = [];

        for (let key in response) {
          let value = response[key];
          value.forEach((rates) => {
            ratesArr.push(rates);
          });
        }

        let dataMap = Array.from(new Set(ratesArr.map(s => s.code)))
          .map(cod => {
            return {
              type: 'area',
              name: cod,
              data: ratesArr.filter(s => s.code === cod).map(el => {
                const splitDate = el.categories.split('-'),
                  getDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]),
                  getTime = getDate.getTime() + (7200 * 1000);

                return [getTime, Number(el.mid)];
              }),
              visible: false,
              fillColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: stops,
              },
            };
          });

        dataMap[Object.keys(dataMap)[0]].visible = true;

        return dataMap;
      };

      // Basic variables for Chart
      const exchangeRateDateArr = [];
      getData(data)[0].data.filter(s => {
        exchangeRateDateArr.push(s[0]);
      });

      const exchangeRateArr = [];
      getData(data)[0].data.filter(s => {
        exchangeRateArr.push(s[1]);
      });
      const firstMinMid = Math.min(...exchangeRateArr);

      // Main function init Chart
      if (chartContainer != null) {

        // Highcharts settings
        Highcharts.setOptions({
          lang: {
            rangeSelectorZoom: setArrLang()['Zoom'][chartLang],
            decimalPoint: chartLang === 'pl'? ',' : '.',
            thousandsSep: ' ',
            shortMonths: setArrLang()['MonthShort'][chartLang],
            months: setArrLang()['MonthFull'][chartLang],
            weekdays: setArrLang()['DayFull'][chartLang],
          },
        });

        // Generate the chart
        window.chart = new Highcharts.Chart({
            series: getData(data),
            chart: {
              renderTo: chartContainer,
              height: 463,
              type: 'reaspline',
              spacing: [0, 20, 15, 0],
              backgroundColor: 'transparent',
            },
            boost: {
              allowForce: true,
              useGPUTranslations: true,
            },
            title: {
              text: '',
            },
            credits: '',
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'top',
              alignColumns: true,
              margin: 0,
              itemWidth: 100,
              itemMarginTop: 0,
              itemMarginBottom: 30,
              itemStyle: {
                'color': '#152E52',
                'cursor': 'pointer',
                'fontSize': '14px',
                'fontWeight': '400',
              },
              itemCheckboxStyle: {
                'width': '12px',
                'height': '12px',
                'border': '1.5px solid #4A74B0',
              },
              useHTML: true,
              labelFormatter: function () {
                return '<div class="wrapper"><span class="square"></span><span class="label">' + this.name + '</span></div>';
              }
            },
            xAxis: {
              type: 'datetime',
              tickInterval: 86400000, // one day interval
              minorGridLineColor: '#E5E5E5',
              minorGridLineWidth: 1,
              minorTickInterval: 86400000, // change interval
              gridLineColor: '#C8C8C8',
              gridLineDashStyle: 'Dash',
              labels: {
                style: {
                  color: '#4B4B4B',
                  fontSize: '14px',
                }
              },
            },
            yAxis: {
              title: {
                text: setArrLang()['yAxisTitle'][chartLang],
                margin: 25,
                style: {
                  color: '#333333',
                  fontSize: '18px',
                }
              },
              labels: {
                format: '{value:.1f}',
                style: {
                  color: '#333333',
                  fontSize: '15px',
                }
              },
              gridLineColor: '#C8C8C8',
              gridLineDashStyle: 'Dash',
              tickInterval: 0.1,
              min: firstMinMid,
            },
            plotOptions: {
              series: {
                animation: true,
                color: '#10BBEF',
                marker: {
                  fillColor: '#2F578C',
                  symbol: 'circle',
                  radius: 4,
                  lineWidth: 2,
                  radiusPlus: 2,
                  enabledThreshold: 5,
                  states: {
                    hover: {
                      radius: 4,
                      radiusPlus: 0,
                      fillColor: '#10BBEF',
                      lineColor: '#10BBEF',
                      lineWidth: 2,
                      lineWidthPlus: 0,
                    }
                  }
                },
                events: {
                  show: function () {
                    let chart = this.chart,
                      series = chart.series,
                      i = series.length,
                      otherSeries;

                    while (i--) {
                      otherSeries = series[i];
                      if (otherSeries != this && otherSeries.visible) {
                        otherSeries.hide();
                      }
                    }
                  },
                  legendItemClick: function () {
                    const minArr = [];
                    this.data.filter(s => {
                      minArr.push(s.y);
                    });

                    this.yAxis.update({
                        min: Math.min(...minArr) - 0.035,
                      }
                    );

                    if (this.visible) {
                      return false;
                    }
                  },
                },
              },
              line: {
                accessibility: {
                  description: setArrLang()['Title'][chartLang],
                  enabled: true,
                  keyboardNavigation: {
                    enabled: true,
                  },
                },
                cursor: 'pointer',
              },
            },
            tooltip: {
              useHTML: true,
              formatter: function () {
                return '<div class="wrapper"><div class="value">' + Highcharts.numberFormat(this.y, 4) + '</div><div' +
                  ' class="date">' + Highcharts.dateFormat('%e. %b',
                  new Date(this.x)) + '</div></div>';
              },
              shared: true,
              animation: true,
              backgroundColor: '#FFFFFF',
              borderColor: undefined,
              borderRadius: 0,
              borderWidth: 0,
              hideDelay: 500,
              padding: 18,
            },
            responsive: {
              rules: [{
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  chart: {
                    height: 400,
                    spacing: [0, 0, 0, -5],
                  },
                  yAxis: {
                    title: {
                      margin: 5,
                    },
                  },
                  xAxis: {
                    min: exchangeRateDateArr[0],
                    max: exchangeRateDateArr[(exchangeRateDateArr.length / 2) - 1],
                    scrollbar: {
                      enabled: true,
                      barBackgroundColor: 'transparent',
                      barBorderRadius: 7,
                      barBorderWidth: 0,
                      buttonBackgroundColor: 'white',
                      buttonBorderWidth: 0,
                      buttonArrowColor: '#606060',
                      buttonBorderRadius: 15,
                      height: 30,
                      rifleColor: 'transparent',
                      trackBackgroundColor: 'transparent',
                      trackBorderWidth: 0,
                      trackBorderColor: 'transparent',
                      trackBorderRadius: 0,
                      zIndex: 1000
                    }
                  },
                  legend: {
                    layout: 'horizontal',
                    align: 'left',
                    verticalAlign: 'top',
                    width: 300,
                    x: 0,
                    itemWidth: 100,
                    margin: 10,
                    itemMarginTop: 0,
                    itemMarginBottom: 10,
                  },
                  plotOptions: {
                    series: {
                      marker: {
                        fillColor: '#2F578C',
                        symbol: 'circle',
                        radius: 4,
                        lineWidth: 2,
                        radiusPlus: 2,
                        enabledThreshold: 1,
                        states: {
                          hover: {
                            radius: 4,
                            radiusPlus: 0,
                            fillColor: '#10BBEF',
                            lineColor: '#10BBEF',
                            lineWidth: 2,
                            lineWidthPlus: 0,
                          }
                        }
                      },
                    },
                  }
                },
              }],
            },
          },
          () => {
            const switcherContrast = document.querySelector('.switcher-contrast');

            switcherContrast.addEventListener('click', () => {
              initExchangeRatesMonthChart();
            });

            const arrows = document.querySelectorAll('.highcharts-scrollbar-arrow');
            arrows.forEach((arrow, index) => {
              if (index === 0 || index === 2) {
                arrow.setAttribute('d', 'M4.27156 10.1343L4.80189 10.6647L5.86255 9.604L5.33222 9.07367L4.27156 10.1343ZM1 5.80212L0.46967 5.27179L-0.0606608 5.80212L0.469671 6.33245L1 5.80212ZM5.33222 2.53056L5.86255 2.00023L4.80189 0.939573L4.27156 1.4699L5.33222 2.53056ZM5.33222 9.07367L1.53033 5.27179L0.469671 6.33245L4.27156 10.1343L5.33222 9.07367ZM1.53033 6.33245L5.33222 2.53056L4.27156 1.4699L0.46967 5.27179L1.53033 6.33245ZM10 4.85485L1 4.85485V6.35485L10 6.35485V4.85485Z');
              } else if (index === 1 || index === 3) {
                arrow.setAttribute('d', 'M5.72844 1.47065L5.19811 0.940316L4.13745 2.00098L4.66778 2.53131L5.72844 1.47065ZM9 5.80286L9.53033 6.33319L10.0607 5.80286L9.53033 5.27253L9 5.80286ZM4.66778 9.07442L4.13745 9.60475L5.19811 10.6654L5.72844 10.1351L4.66778 9.07442ZM4.66778 2.53131L8.46967 6.33319L9.53033 5.27253L5.72844 1.47065L4.66778 2.53131ZM8.46967 5.27253L4.66778 9.07442L5.72844 10.1351L9.53033 6.33319L8.46967 5.27253ZM0 6.75013H9V5.25013H0V6.75013Z');
              }
              arrow.setAttribute('fill', '#606060');
            });
          }
        );
      }
    });
  }
};

export { initExchangeRatesMonthChart };
