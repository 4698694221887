import xml2js from 'xml2js';

const lang = document.documentElement.lang.slice(0, 2);

const createTable = (container, table, obj) => {
  const insertHeaders = (table, obj) => {
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const row = table.insertRow();

    for (let i = 0; i < obj.length; i++) {
      let cell = document.createElement('th');
      row.appendChild(cell);
      cell.appendChild(document.createTextNode(capitalizeFirstLetter(obj[i])));
    }
  };

  const insertTable = (table, obj, lang) => {
    for (let i = 0; i < obj.body.length; i++) {
      let child = obj.body[i];
      const row = table.insertRow();

      Object.values(child[lang]).forEach(function (k) {
        let cell = row.insertCell();
        cell.innerHTML = k;
      });
    }
  };

  if (lang === 'pl') {
    insertHeaders(table, obj.header.pl);
    insertTable(table, obj, 'pl');
  } else {
    insertHeaders(table, obj.header.eng);
    insertTable(table, obj, 'eng');
  }

  const tableResponsive = document.createElement('div');
  tableResponsive.classList.add('table-responsive');

  tableResponsive.appendChild(table);
  container.appendChild(tableResponsive);

  return container;
};

const createParagraph = (container, obj) => {
  if (lang === 'pl') {
    for (let i = 0; i < obj.pl.length; i++) {
      const p = document.createElement('p');
      p.innerHTML = obj.pl[i];
      container.appendChild(p);
    }
  } else {
    for (let i = 0; i < obj.eng.length; i++) {
      const p = document.createElement('p');
      p.innerHTML = obj.eng[i];
      container.appendChild(p);
    }
  }

  return container;
};

const appendInterestRates = (tableContainer) => {
  // eslint-disable-next-line no-undef
  const xmls = ajax_object.data_body,
    parser = new xml2js.Parser();

  for (const key in xmls) {
    // eslint-disable-next-line no-prototype-builtins
    if (xmls.hasOwnProperty(key)) {
      if (key.indexOf('stopy_procentowe') > -1) {
        // eslint-disable-next-line no-undef
        parser.parseStringPromise(xmls[key]).then(function (result) {
          const links = result.stopy_procentowe?.odnosniki[0] || [];
          const interestRatesArr = result.stopy_procentowe?.tabela || [];

          // START Generate Interest rates table and display Reserve requirement table
          interestRatesArr.forEach((obj) => {
            const objBody = obj.pozycja.map(item => {
              let link = item.$.odnosnik ? ' <sup>' + item.$.odnosnik + ')</sup>' : '';
              return {
                pl: {
                  name: item.$.nazwa + link,
                  interest: item.$.oprocentowanie,
                  date: item.$.obowiazuje_od,
                },
                eng: {
                  name: item.$.name + link,
                  interest: item.$.oprocentowanie.replace(',', '.'),
                  date: item.$.obowiazuje_od,
                }
              };
            });

            const objArr = {
              header: {
                pl: [
                  obj.$.naglowek,
                  'Poziom (w %)',
                  'Obowiązuje od dnia',
                ],
                eng: [
                  obj.$.header,
                  'Level (in %)',
                  'In effect from',
                ]
              },
              body: objBody
            };

            const table = document.createElement('table');
            table.classList.add('table', 'table-hover', 'table-striped', 'table-bordered');

            createTable(tableContainer, table, objArr);
          });
          // END Generate Interest rates table and display Reserve requirement table

          // START Generate links
          let linksObj = {};
          linksObj = {
            pl: [
              links.odnosnik['0'].$.id + ') ' + links.odnosnik['0'].$.odnosnik,
              links.odnosnik['1'].$.id + ') ' + links.odnosnik['1'].$.odnosnik,
            ],
            eng: [
              links.odnosnik['0'].$.id + ') ' + links.odnosnik['0'].$.note,
              links.odnosnik['1'].$.id + ') ' + links.odnosnik['1'].$.note,
            ]
          };

          createParagraph(tableContainer, linksObj);
          // END Generate links
        });
      }
    }
  }

};

export { appendInterestRates };
