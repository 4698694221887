// Make table from data.
const lang = document.documentElement.lang.slice(0, 2);

const createTable = (obj, type) => {
  // Extract value from HTML header.
  let col = [];
  for (let i = 0; i < obj.length; i++) {
    for (let key in obj[i]) {
      if (col.indexOf(key) === -1) {
        col.push(key);
      }
    }
  }

  const tableResponsive = document.createElement('div');
  tableResponsive.classList.add('table-responsive');

  // Create dynamic table.
  let table = document.createElement('table');
  table.classList.add('table', 'table-hover', 'table-striped', 'table-bordered');

  // Create HTML table header row using the extracted headers above.
  let tr = table.insertRow(-1), // Table row.
    countUndefined = 0;

  for (let i = 0; i < col.length; i++) {
    // Table header.
    // eslint-disable-next-line no-unused-vars
    countUndefined++;
    let pattern = new RegExp('^(__EMPTY_?|EMPTY)');
    if (!pattern.test(col[i])) {
      let th = document.createElement('th');
      switch (type) {
        case 'csv':
          th.innerHTML = col[i].replace('??', 'żą');
          tr.appendChild(th);
          break;
        default:
          th.innerHTML = col[i];
          tr.appendChild(th);
          break;
      }
    }
  }

  let rowsLength;
  if (obj.length <= 6) {
    rowsLength = obj.length - 1;
  } else {
    // eslint-disable-next-line no-undef
    rowsLength = ajax_object.row_size ? ajax_object.row_size : 6;
  }

  // Add JSON data to the table as rows.
  for (let i = obj.length - rowsLength; i < obj.length; i++) {
    tr = table.insertRow(-1);
    for (let j = 0; j < col.length; j++) {
      if (typeof obj[i][col[j]] !== 'undefined') {
        let tabCell = tr.insertCell(-1);
        if (lang === 'pl') {
          tabCell.innerHTML = obj[i][col[j]] === '0' ? '0,00' : obj[i][col[j]];
        } else {
          tabCell.innerHTML = obj[i][col[j]] === '0' ? '0,00' : obj[i][col[j]].replace(',', '.');
        }
      }
    }
  }

  // Finally add the newly created table with JSON data to a container.
  return table;
};

export { createTable };
